<template>
  <div class="notification-icon-buy-premium" />
</template>

<style scoped>
.notification-icon-buy-premium {
  width: 25px;
  height: 24px;
  background: center / contain no-repeat
    url("~@/assets/images/icons/notification/notification-buy-premium.png");
}

@media (max-width: 1200px) {
  .notification-icon-buy-premium {
    width: 22px;
    height: 22px;
  }
}
</style>
